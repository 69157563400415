import { createAction, props } from '@ngrx/store';
import {
  Event,
  List,
  Lounge,
  Reservation,
} from '../../../../core/models/app.models';

export const orbitOpened = createAction(
  '[Orbit Container] Orbit opened',
  props<{
    lists: List[];
  }>(),
);

export const initOrbit = createAction(
  '[Orbit Container] Initialize orbit',
  props<{
    eventId: number;
  }>(),
);

export const initOrbitSuccess = createAction(
  '[Orbit Container] Initialize orbit success',
  props<{
    reservations: Reservation[];
    lounges: Lounge[];
  }>(),
);

export const createListClicked = createAction(
  '[Orbit Container] Create List Clicked',
  props<{ event: Event }>(),
);

export const openListClicked = createAction(
  '[Orbit Container] Open List Clicked',
  props<{ list: List }>(),
);

export const editListClicked = createAction(
  '[Orbit Container] Edit List Clicked',
  props<{ list: List }>(),
);

export const deleteListClicked = createAction(
  '[Orbit Container] Delete List Clicked',
  props<{ list: List }>(),
);

export const deleteListSuccessful = createAction(
  '[Orbit Container] Delete List Successful',
);

export const deleteListFailed = createAction(
  '[Orbit Container] Delete List failed',
);
export const backClicked = createAction('[Orbit Container] Back Clicked');

export const openAllListsClicked = createAction(
  '[Orbit Container] All Lists Clicked',
  props<{ event: Event }>(),
);

export const openAllInviteesClicked = createAction(
  '[Orbit Container] All Invitees Clicked',
  props<{ event: Event }>(),
);

export const openAllReservationsClicked = createAction(
  '[Orbit Container] All Reservations Clicked',
  props<{ event: Event }>(),
);

export const openAllLoungesClicked = createAction(
  '[Orbit Container] All Lounges Clicked',
  props<{ event: Event }>(),
);

export const checkInLoungeClicked = createAction(
  '[Orbit Container] Check In Lounge Clicked',
  props<{ lounge: Lounge }>(),
);

export const checkInLoungeSuccessful = createAction(
  '[Orbit Container] CheckIn Lounge Successful',
);

export const checkInLoungeFailed = createAction(
  '[Orbit Container] CheckIn Lounge failed',
);

export const editLoungeClicked = createAction(
  '[Orbit Container] Edit Lounge Clicked',
  props<{ lounge: Lounge }>(),
);

export const deleteLoungeClicked = createAction(
  '[Orbit Container] Delete Lounge Clicked',
  props<{ lounge: Lounge }>(),
);

export const deleteLoungeSuccessful = createAction(
  '[Orbit Container] Delete Lounge Successful',
  props<{ loungeId: number }>(),
);

export const deleteLoungeFailed = createAction(
  '[Orbit Container] Delete Lounge failed',
);

export const openReservationRequestClicked = createAction(
  '[Orbit Container] Open Reservation Request Clicked',
  props<{ reservation: Reservation }>(),
);

export const addPackage = createAction('[Orbit Container] Add package');

export const editPackage = createAction(
  '[Orbit Container] Edit package',
  props<{ packageId: number }>(),
);

export const deletePackage = createAction(
  '[Orbit Container] Delete package',
  props<{ packageId: number }>(),
);

export const deletePackageSuccess = createAction(
  '[Orbit Container] Delete package success',
  props<{ packageId: number }>(),
);

export const reservationRequestProcessed = createAction(
  '[Orbit Container] Reservation request processed',
  props<{ reservationId: number }>(),
);

export const assignReservationClicked = createAction(
  '[Orbit Container] Assign Reservation Clicked',
  props<{
    reservation: Reservation;
  }>(),
);

export const assignReservationSuccessful = createAction(
  '[Orbit Container] Assign Reservation Successful',
  props<{ reservationId: number }>(),
);

export const assignReservationFailed = createAction(
  '[Orbit Container] Assign Reservation Failed',
);

export const cancelReservationSuccessful = createAction(
  '[Orbit Container] Cancel Reservation Successful',
  props<{ reservationId: number }>(),
);

export const cancelReservationFailed = createAction(
  '[Orbit Container] Cancel Reservation Failed',
);

export const cancelReservationClicked = createAction(
  '[Orbit Container] Cancel Reservation Clicked',
  props<{
    reservation: Reservation;
  }>(),
);
