import { createFeature, createReducer, on } from '@ngrx/store';
import { addDays, format } from 'date-fns';
import { MyEventsContainerActions } from 'src/app/core/store/actions';
import { OrbitContainerActions } from './index';
import { OrbitState } from './orbit.state';
import { mapEventToEventInformation } from '../../../core/helpers/mappers';

export const initialState: Readonly<OrbitState> = {
  eventInformation: {
    name: '',
    startDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(addDays(new Date(), 2), 'yyyy-MM-dd'),
    startTime: '',
    endTime: '',
    fromPrice: '',
    pressText: '',
    ticketLink: '',
    ageRestrictionWomen: '',
    ageRestrictionMen: '',
    internalLabel: -1,
    fyraTicketLink: '',
    location: undefined,
    locationName: '',
    locationStreet: '',
    locationPlz: '',
    locationProvince: '',
    eventId: undefined,
    genres: [],
    tags: [],
    flyer: null,
  },
  lists: null,
  openList: {
    id: 0,
    name: '',
    namePromoter: '',
    emailPromoter: '',
    event: null,
    link: '',
    listType: null,
    maxInvitees: 0,
    price: '',
    validTillDate: '',
    validTillTime: '',
    listInvitee: [],
  },
  editList: {
    id: 0,
    name: '',
    namePromoter: '',
    emailPromoter: '',
    event: null,
    link: '',
    listType: null,
    maxInvitees: 0,
    price: '',
    validTillDate: '',
    validTillTime: '',
    listInvitee: [],
  },
  editLounge: {
    id: 0,
    name: '',
    comment: '',
    loungeType: null,
    numberSeats: 0,
    event: null,
    reservation: null,
    loungeStatus: 'available',
    packages: [],
    locationId: 0,
    location: null,
  },
  lounges: [],
  packages: [],
  reservations: [],
};

export const orbitReducers = createReducer(
  initialState,
  on(MyEventsContainerActions.openOrbitClicked, (state, { event }) => ({
    ...state,
    eventInformation: mapEventToEventInformation(event),
    packages: event.location?.packages ?? [],
  })),
  on(OrbitContainerActions.orbitOpened, (state, { lists }) => ({
    ...state,
    lists: lists,
  })),
  on(OrbitContainerActions.openListClicked, (state, { list }) => ({
    ...state,
    openList: list,
  })),
  on(OrbitContainerActions.editListClicked, (state, { list }) => ({
    ...state,
    editList: list,
  })),
  on(OrbitContainerActions.editLoungeClicked, (state, { lounge }) => ({
    ...state,
    editLounge: lounge,
  })),
  on(OrbitContainerActions.deletePackageSuccess, (state, { packageId }) => ({
    ...state,
    packages: state.packages.filter((x) => x.id !== packageId),
  })),
  on(
    OrbitContainerActions.initOrbitSuccess,
    (state, { reservations, lounges }) => ({
      ...state,
      reservations,
      lounges,
    }),
  ),
  on(
    OrbitContainerActions.assignReservationClicked,
    (state, { reservation }) => ({
      ...state,
      reservations: state.reservations.filter((x) => x.id !== reservation!.id!),
    }),
  ),
  on(
    OrbitContainerActions.cancelReservationClicked,
    (state, { reservation }) => ({
      ...state,
      reservations: state.reservations.filter((x) => x.id !== reservation!.id!),
    }),
  ),
  on(OrbitContainerActions.deleteLoungeSuccessful, (state, { loungeId }) => ({
    ...state,
    lounges: state.lounges.filter((x) => x.id !== loungeId),
  })),
);

export const orbitFeature = createFeature({
  name: 'orbit',
  reducer: orbitReducers,
});
