import * as OrbitContainerActions from './actions/orbit-container.actions';
import * as OrbitContainerActions2 from '../../../core/store/actions/orbit-container.actions';
import { orbitFeature } from './orbit.reducers';

export * as orbitState from './orbit.state';

const { reducer, name, ...orbitSelectors } = orbitFeature;

export {
  reducer as orbitReducer,
  name as orbitName,
  OrbitContainerActions,
  OrbitContainerActions2,
  orbitSelectors,
};
